body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#fontRoboto, .fontRoboto{
  font-family: "Roboto" !important;
}
code {
  font-family: "Roboto",  sans-serif!important;
}

@font-face {
    font-family: "Entypo";
    src: url(/static/media/Entypo.744ce600.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "EvilIcons";
    src: url(/static/media/EvilIcons.140c53a7.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "FontAwesome";
    src: url(/static/media/FontAwesome.b06871f2.ttf)
      format("truetype");
  }
  
  @font-face {
    font-family: "fontcustom";
    src: url(/static/media/Foundation.e20945d7.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "Ionicons";
    src: url(/static/media/Ionicons.b2e0fc82.ttf) format("truetype");
  }
  
  @font-face {
    /*font-family: 'MaterialCommunityIcons';*/
    font-family: "Material Design Icons";
    src: url(/static/media/MaterialCommunityIcons.baded941.ttf)
      format("truetype");
  }
  
  @font-face {
    font-family: "MaterialIcons";
    src: url(/static/media/MaterialIcons.a37b0c01.ttf)
      format("truetype");
  }
  
  @font-face {
    font-family: "Octicons";
    src: url(/static/media/Octicons.8e7f807e.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "simple-line-icons";
    src: url(/static/media/SimpleLineIcons.d2285965.ttf)
      format("truetype");
  }
  
  @font-face {
    font-family: "Zocial";
    src: url(/static/media/Zocial.5cdf883b.ttf) format("truetype");
  }
